import React, {useEffect, useRef, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import { faArrowDown as falArrowDown } from '@fortawesome/pro-light-svg-icons';
import { faArrowDown as fatArrowDown } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Checkers from '../../../helpers/class/Checkers';
import BottomSections from '../../BottomSections';
import Header from '../../Header';
import Jumbotron from '../../Jumbotron';
import FeaturedImages from '../../jumbotron/FeaturedImages';
import ReadMoreText from '../../ReadMoreText';
import ViewPort from '../../ViewPort';

import ArticleTypes from './ArticleTypes';
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../helpers/MethodHelper";
import TabsContentWrapper from "../properties/partials/TabsContentWrapper";
import ServiceContent from "../property/partials/services/ServiceContent";
import {useInViewport} from "react-in-viewport";
import {faCheck as faCheck, faXmark} from "@fortawesome/pro-regular-svg-icons";
import {faCalendar, faTag, faClock, faCreditCard} from "@fortawesome/pro-solid-svg-icons";
import ContactForm from "../../contact/ContactForm";
import Link from "next/link";

interface ArticleCategoriesContainerInterface {
  app_data?: any
  app?: any
  builder?: any
  type?: any
  content?: any
  isDesktop?: boolean
  articlesCategories?: any
  categoryIdInhrerited?: string
  includeJumbotron?: boolean
  hideTitle?: boolean
  includeTitle?: boolean
  inheritedViewType?: string
  articlesLimit?: number
  backgroundColor?: string
  textColor?: string
  serviceType?: string
  itemClassName?: string
  itemWrapperClassName?: string
  titleClassName?: string
  subtitleInherited?: string
  paddingTopClassName?: string
  paddingBottomClassName?: string
  viewMoreBtnStyle?: 'primary'|'secondary'
  showAllLabel?: string
}

export default function ArticleCategoriesContainer ( props : ArticleCategoriesContainerInterface ) {

  const showFullContent = props.content.hasOwnProperty('showFullContent') ? props.content.showFullContent : false

  const contentFullwidth = props.content.hasOwnProperty('contentFullwidth') ? props.content.contentFullwidth : false

  const hideTitleInnerPage = props.content.hasOwnProperty('hideTitleInnerPage') ? props.content.hideTitleInnerPage : false

  const titleClassName = props.titleClassName!=undefined ? props.titleClassName : ''
  const subtitleInherited = props.subtitleInherited!=undefined ? props.subtitleInherited : ''
  const paddingTop = props.paddingTopClassName!=undefined ? props.paddingTopClassName : 'p-t-100'
  const paddingBottomClassName = props.paddingBottomClassName!=undefined ? props.paddingBottomClassName : ''
  const viewMoreBtnStyle = props.viewMoreBtnStyle!=undefined ? props.viewMoreBtnStyle : 'primary'

  const showAllLabel = props.hasOwnProperty('showAllLabel') ? props.showAllLabel : "Show all"

  const articleSectionContent = Checkers.isValidString(props.content.section_content) ? props.content.section_content : null

  const articleSectionJumbotronContent = Checkers.isValidString(props.content.section_jumbotron) ? props.content.section_jumbotron : null

  const articleSectionRef = useRef()

  const app_data = props.app_data;
  const app = props.app;

  const builder = props.builder;
  const content_type = Checkers.isValidString(props.type) && props.type;

  const hideArticles = props.content.hide_articles

  const subtitle = content_type == 'category' ? props.content.description : props.content.subtitle;

  let viewType = (props.inheritedViewType  != undefined ? props.inheritedViewType : (Checkers.isValidObjString(builder, 'builder_news_view_type') ? builder.builder_news_view_type : '172') );

  if ( props.inheritedViewType  == undefined && (content_type == 'category' || content_type == 'experiences') ) {
    viewType = Checkers.isValidObjString(props.content, 'view_type') ? props.content.view_type : '172';
  }

  const containerClassname = () => {
    if (props.content.category_view_width == "container") {
      return props.content.category_view_width
    }

    return props.content.category_view_width + " p-l-0 p-r-0"
  }

  const hideImage = () => {
    return props.content.hide_image
  }

  const backgroundColor = () => {
    if (props.backgroundColor!=undefined) {
      return props.backgroundColor
    }

    if (content_type == 'blog') {
      if (app.builder.builder_news_bg_color != null && Checkers.isHexColor(app.builder.builder_news_bg_color)) {
        return app.builder.builder_news_bg_color
      }

    } else {
      if (props.content.background_color != null && Checkers.isHexColor(props.content.background_color)) {
        return props.content.background_color
      }
    }

    return null
  }

  const textColor = () => {
    if (props.textColor !=undefined) {
      return props.textColor;
    }

    if (content_type == 'blog') {
      if (app.builder.builder_news_dark_theme_color != 1) {
        return "text-light-color"
      }

      return ""
    } else {
      if (props.content.dark_text != undefined && props.content.dark_text == true) {
        return "text-light-color"
      }

      return ""
    }
  }

  const showTitle = () => {
    if (props.includeTitle != undefined && props.includeTitle === false) {
      return false;
    }

    return true;
  }

  const [reactComponentsArrayLoading, setReactComponentsArrayLoading] = useState(true)
  const [tabsArrayComponents, setTabsArrayComponents] = useState([])

  const checkAvailableDaysForExperience = () => {
    return props.content.availableMonday ||
        props.content.availableTuesday ||
        props.content.availableWednesday ||
        props.content.availableThursday ||
        props.content.availableFriday ||
        props.content.availableSaturday ||
        props.content.availableSunday
  }

  const hasDuration =  () => {
    return props.content.bookingType == '191' && Checkers.isValidString(props.content.duration)
  }

  const hasPaymentPeriod = () => {
    return props.content.bookingType == '193' && Checkers.isValidString(props.content.paymentPeriodType)
  }

  const captchaSiteKey = useSelector(state => state['appSettings']['captchaSiteKey'])

  const getTabbedContent = () => {
    let result = [];

    let details = props.content.details

    let expectations = props.content.expectations
    let highlights = props.content.highlights

    let availableDays = [
      {dayOfTheWeek: "Monday", available: props.content.availableMonday},
      {dayOfTheWeek: "Tuesday", available: props.content.availableTuesday},
      {dayOfTheWeek: "Wednesday", available: props.content.availableWednesday},
      {dayOfTheWeek: "Thursday", available: props.content.availableThursday},
      {dayOfTheWeek: "Friday", available: props.content.availableFriday},
      {dayOfTheWeek: "Saturday", available: props.content.availableSaturday},
      {dayOfTheWeek: "Sunday", available: props.content.availableSunday}
    ]

    let basePrice = props.content.basePrice

    let duration = props.content.duration

    let paymentPeriodTypeLabel = props.content.paymentPeriodTypeLabel

    let enableInquire = props.content.enableInquire

    if ( Checkers.isValidString(details) ) {
      result.push({
        tabId: "important-info",
        tabTitle: 'Important info',
        tabContent: <><div dangerouslySetInnerHTML={{__html: details}} /></>
      })
    }

    if ( Checkers.isValidString(expectations) || Checkers.isValidString(highlights) ) {
      result.push({
        tabId: "overview",
        tabTitle: 'Overview',
        tabContent: <>
          {
            Checkers.isValidString(highlights) &&
            <>
              <div className={'m-b-30'} dangerouslySetInnerHTML={{__html: highlights}} />
            </>
          }
          {
            Checkers.isValidString(expectations) &&
            <>
              <div dangerouslySetInnerHTML={{__html: expectations}} />
            </>
          }
        </>
      })
    }

    if ( checkAvailableDaysForExperience() || Checkers.isValidPositiveNumber(basePrice) || hasDuration() || hasPaymentPeriod() ) {
      result.push({
        tabId: "details",
        tabTitle: 'Details',
        tabContent: <>
          {
              checkAvailableDaysForExperience() &&
              <>
                <div className={'panel m-b-20'}>
                  <p className={'text-design-color h6 m-b-20'}><FontAwesomeIcon icon={faCalendar} />&nbsp;Available days</p>
                  {
                    availableDays.map( (item) => {
                      return (
                          <>
                            <p>
                              {item.dayOfTheWeek}&nbsp;
                              {
                                item.available ?
                                    <>
                                      <FontAwesomeIcon icon={faCheck} className={'text-success'} />
                                    </>
                                    :
                                    <>
                                      <FontAwesomeIcon icon={faXmark} className={'text-danger'} />
                                    </>
                              }
                            </p>
                          </>
                      )
                    })
                  }
                </div>
              </>
          }
          {
              hasDuration() &&
              <>
                <div className={'panel m-b-20'}>
                  <p className={'text-design-color h6 m-b-20'}>
                    <FontAwesomeIcon icon={faClock} />
                    &nbsp;Duration&nbsp;
                    <span className={'text-muted'}>
                      {
                        Checkers.isValidNumber(duration) ?
                            duration + " hours"
                            :
                            duration
                      }
                    </span>
                  </p>
                </div>
              </>
          }
          {
              hasPaymentPeriod() &&
              <>
                <div className={'panel m-b-20'}>
                  <p className={'text-design-color h6 m-b-20'}>
                    <FontAwesomeIcon icon={faCreditCard} />
                    &nbsp;Payment type&nbsp;
                    <span className={'text-muted'}>{paymentPeriodTypeLabel}</span>
                  </p>
                </div>
              </>
          }
          {
              Checkers.isValidPositiveNumber(basePrice) &&
              <>
                <p className={'text-design-color h6 m-b-20'}>
                  <FontAwesomeIcon icon={faTag} />
                  &nbsp;Price&nbsp;
                  <span className={'text-muted'}>€&nbsp;{basePrice}</span>
                </p>
              </>
          }
        </>
      })
    }

    if ( enableInquire ) {
      result.push({
        tabId: "inquiry",
        tabTitle: 'Inquiry',
        tabContent:
            <>
              <ContactForm
                  captchaSiteKey={captchaSiteKey}
                  showDestination={false}
                  showDates={false}
                  containerClass={'col-12'}
                  experienceId={props.content.id}
                  experienceTitle={props.content.title}
                  insideSingleProperty={false}
              />
            </>
      })
    }
    // result.push({
    //   tabId: "tab-4",
    //   tabTitle: 'tab 4',
    //   tabContent: <p>here is a tab 4</p>
    // })

    setTabsArrayComponents(result)
    setReactComponentsArrayLoading(false)
  }

  useEffect( () => {
    if ( reactComponentsArrayLoading ) {
      getTabbedContent()
    }
  })

  const config = {disconnectOnLeave: false};

  const tabsRef = useRef();

  const options = {};

  const {
    inViewport
  } = useInViewport(
      tabsRef,
      options,
      config
  );

  // const articleSectionRefInViewPort =  useInViewport(
  //     articleSectionRef,
  //     options,
  //     config
  // )

  const getCategoryUrl = () => {
    if (Checkers.isValidString(props.content.redirectUrl)) {
      return props.content.redirectUrl
    }

    return "/" +(Checkers.isValidString(props.content.urlSlug) ? props.content.urlSlug : props.content.loggiaUrl)
  }

  const categoryUrlIsBlank = () => {
    if (Checkers.isValidString(props.content.redirectUrl)) {
      return true
    }

    return false
  }

  return (
      <>
        {
          hideArticles && props.categoryIdInhrerited != undefined ?
              <>
                <section className={"p-b-100 " + paddingTop +" bg-master-darkest design_color lazyload-pages-bg-image"}
                         data-pages-bg-image={props.content.images[0] != undefined ? props.content.images[0].image : ''}
                         data-pages-bg-webp={props.content.images[0] != undefined ? props.content.images[0].image : ''}
                         data-pages="parallax"
                         // data-bg-overlay="black" data-overlay-opacity="0.25"
                         style={{
                           minHeight: "300px",
                           backgroundImage: 'url(' + ( props.content.images[0]!=null ?  props.content.images[0].image : '') + ')',
                           backgroundPosition: "center 39.9972%"
                         }}
                >
                  <div className="background-overlay" style={{backgroundColor: 'rgba(0, 0, 0)', opacity: 0.4}}></div>
                  <div className="container text-center sm-p-t-20" style={{zIndex: 9}}>
                    <h6 className="block-title text-white m-b-0">{props.content.title}</h6>
                    <h2 className="text-white m-b-0 m-t-5">{props.content.description}</h2>
                    {
                      props.content.hasBody || props.content.articlesCount > 0 ?
                          <>
                            {
                              categoryUrlIsBlank() ?
                                  <>
                                    <Link  target={'_blank'} rel={'noopener noreferrer'}
                                           href={getCategoryUrl()}>
                                      <a className="p-3 p-l-20 p-r-20 btn btn-white m-t-20">
                                        <span className={'d-flex flex-wrap'}>
                                          Read about {props.content.description}
                                        </span>
                                      </a>
                                    </Link>
                                  </>
                                  :
                                  <>
                                    <Link href={getCategoryUrl()}>
                                      <a className="p-3 p-l-20 p-r-20 btn btn-white m-t-20">
                                        <span className={'d-flex flex-wrap'}>
                                          Read about {props.content.description}
                                        </span>
                                      </a>
                                    </Link>
                                  </>
                            }
                          </>
                          :
                          <>
                          </>
                    }
                  </div>
                </section>
              </>
              :
              <>
                {/*<div id="articles_top"  style={{background: backgroundColor()}}/>*/}
                {/*<div className={containerClassname() + " bg-master-lightest"} style={{background: backgroundColor()}}>*/}
                <section id={props.content.urlSlug} style={{background: backgroundColor()}} className={paddingBottomClassName}>
                  <>
                    {
                        props.includeJumbotron && !hideImage() && ( content_type == 'category' || content_type == 'experiences' ) && Checkers.isValidArray(props.content.images) &&
                        <Jumbotron
                            jumbotronClass=""
                            album={props.content.images}
                            sliderSearchBar={false}
                            isDesktop={props.isDesktop}
                            jumbotronTextColor={app.jumbotronTextColor}
                            jumbotronHeight={Checkers.isValidNumber(builder.builder_article_category_jumbotron_height) ? builder.builder_article_category_jumbotron_height : 75}
                        />
                    }
                    {
                      showTitle() && !props.hideTitle &&
                        <ViewPort
                            parentClassName={'container mt-5 mb-5'}
                            skeletonItem={
                              <div className="row text-center">
                                <Skeleton height={43} className="col-8 col-sm-6 col-md-4 b-rad-md mb-1"/>
                                <Skeleton height={55} className="col-12 col-sm-12 col-md-8 b-rad-md mb-2"/>
                                <Skeleton height={45} className="col-10 col-sm-8 col-md-6 b-rad-md mb-1"/>
                              </div>
                            }
                        >
                              {
                                // !props.hideTitle ?
                                  <>
                                    <div className={" pb-4 " + (props.categoryIdInhrerited != undefined ? paddingTop : 'pt-5')}
                                         style={{background: backgroundColor()}}>
                                      <div className={containerClassname() + (contentFullwidth ? " container-fluid " : " container " ) + "bg-master-lightest"}
                                           style={{background: backgroundColor()}}>
                                        <div className={"row justify-content-center w-100 m-0 " + titleClassName}>
                                          <div className={(contentFullwidth ? "p-0" : "") + " col-12 col-sm-12 text-center d-flex flex-column " + (Checkers.isValidString(titleClassName) ? titleClassName : 'align-items-center') }>
                                            {
                                              (content_type == 'blog_article' || content_type == 'article' || content_type == 'excursion') ?
                                                  <>
                                                    {content_type == 'blog_article' &&
                                                        <h5 className="block-title mb-0">
                                                          {moment(props.content.created * 1000).format('dddd Do MMMM')}
                                                        </h5>
                                                    }

                                                    {
                                                      hideTitleInnerPage ?
                                                          <>
                                                          </>
                                                          :
                                                          <>
                                                          {
                                                            props.categoryIdInhrerited != undefined ?
                                                                <>
                                                                  <h2 className={textColor() + " mt-1 fw-normal hidden-xs hidden-sm hidden-md m-b-0"}>{props.content.title}</h2>
                                                                  <h2 className={textColor() + "mt-1 fw-normal visible-xs visible-sm visible-md m-b-0"}>{props.content.title}</h2>
                                                                </>
                                                                :
                                                                <>
                                                                  <h1 className={textColor() + " mt-1 fw-normal hidden-xs hidden-sm hidden-md"}>{props.content.title}</h1>
                                                                  <h2 className={textColor() + "mt-1 fw-normal visible-xs visible-sm visible-md"}>{props.content.title}</h2>
                                                                </>
                                                          }
                                                          </>
                                                    }
                                                    <p className={textColor()}>{props.content.subtitle}</p>
                                                  </>
                                                  :
                                                  <>

                                                    {
                                                      hideTitleInnerPage ?
                                                          <>
                                                          </>
                                                          :
                                                          <>
                                                          {
                                                            props.categoryIdInhrerited != undefined ?
                                                                <>
                                                                  {
                                                                    Checkers.isValidString(subtitleInherited) ?
                                                                        <p className={"block-title m-t-40 hint-text no-margin " + titleClassName}>{subtitleInherited}</p>
                                                                        :
                                                                        <></>
                                                                  }
                                                                  <h2 className={textColor() + " mt-1 fw-normal m-b-0 " + titleClassName}>{props.content.title}</h2>
                                                                  {
                                                                    Checkers.isValidString(subtitle) ?
                                                                        <h4 className={textColor() + " block-title mb-0 m-t-0 " + titleClassName}>{subtitle}</h4>
                                                                        :
                                                                        <></>
                                                                  }
                                                                </>
                                                                :
                                                                <>
                                                                  <h1 className={textColor() + " mt-1 fw-normal"}>{props.content.title}</h1>
                                                                  {
                                                                    Checkers.isValidString(subtitle) ?
                                                                        <h5 className={textColor() + " block-title mb-0 fs-20"}>{subtitle}</h5>
                                                                        :
                                                                        <></>
                                                                  }
                                                                </>
                                                          }
                                                            {
                                                              props.categoryIdInhrerited != undefined ?
                                                                  <>
                                                                  </>
                                                                  :
                                                                  <>
                                                                    <FontAwesomeIcon
                                                                        className={textColor() + " text-master opacity-50 py-4 hidden-xs hidden-sm hidden-md"}
                                                                        icon={fatArrowDown} size="2x"/>
                                                                    <FontAwesomeIcon
                                                                        className={textColor() + " text-master opacity-50 py-3 fs-16 visible-xs visible-sm visible-md"}
                                                                        icon={falArrowDown}/>
                                                                  </>
                                                            }
                                                          </>

                                                    }
                                                    {
                                                        showFullContent ?
                                                            <>
                                                              <div
                                                                  className={textColor() + " " + (Checkers.valueToBoolean(builder.builder_general_description_centered) ? 'text-md-center' : 'text-md-justify')}>
                                                                  <div dangerouslySetInnerHTML={{__html: props.content.body}}/>
                                                              </div>
                                                            </>
                                                                :
                                                            <>
                                                              {
                                                                  (content_type == 'category' || content_type == 'experiences') &&
                                                                  <ReadMoreText
                                                                      className={textColor() + " " + (Checkers.valueToBoolean(builder.builder_general_description_centered) ? 'text-md-center' : 'text-md-justify')}
                                                                      lines={4} text={props.content.body}
                                                                      isDesktop={props.isDesktop}/>
                                                              }
                                                            </>

                                                    }
                                                  </>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  </>
                                    // :
                                    // <></>
                              }
                        </ViewPort>
                    }

                    {
                        Checkers.isValidArray(props.content.images) && (content_type == 'blog_article' || content_type == 'article' || content_type == 'excursion') &&
                        <ViewPort
                            parentClassName={'container mb-5'}
                            skeletonItem={
                              <div className="row text-center">
                                <Skeleton height={500} className="col-12 col-sm-12 col-md-10 b-rad-md mb-1"/>
                              </div>
                            }
                        >
                          <Jumbotron jumbotronClass="mb-5">
                            <div className="container h-100">
                              <div className="row justify-content-center h-100">
                                <div className="col-12 col-sm-12 col-md-12">
                                  <FeaturedImages
                                      fancybox={true}
                                      dataFancybox={'blog-images'}
                                      isDesktop={props.isDesktop}
                                      album={props.content.images}
                                      designMode={app.designMode}
                                  />
                                </div>
                              </div>
                            </div>
                          </Jumbotron>
                        </ViewPort>
                    }

                    <div ref={articleSectionRef} dangerouslySetInnerHTML={{__html: articleSectionJumbotronContent}}/>

                    {
                      (content_type == 'blog_article' || content_type == 'article' || content_type == 'excursion') ?
                          <>
                            {
                                Checkers.isValidString(props.content.description) &&
                                <ViewPort
                                    parentClassName={'container mb-5'}
                                    skeletonItem={
                                      <div className="row text-center">
                                        <Skeleton height={150} className="col-12 col-sm-12 col-md-10 b-rad-md mb-1"/>
                                      </div>
                                    }
                                >
                                  <div className="pb-5" style={{background: backgroundColor()}}>
                                    <div className="container">
                                      <div className="row justify-content-center w-100 m-0">
                                        <div
                                            className={textColor() + ` col-12 col-sm-12 col-md-10 animate-child-height ${Checkers.valueToBoolean(builder.builder_general_description_centered) ? 'text-md-center' : 'text-md-justify'}`}>
                                          {/*<ReadMoreText>*/}
                                          <div dangerouslySetInnerHTML={{__html: props.content.description}}/>
                                          {/*</ReadMoreText>*/}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ViewPort>
                            }
                            {
                              Checkers.isValidString(articleSectionContent) &&
                                <>
                                  <div ref={articleSectionRef} dangerouslySetInnerHTML={{__html: articleSectionContent}}/>
                                </>
                            }
                          </>
                          :
                          (content_type == 'blog' || content_type == 'category' || content_type == 'services' || content_type == 'experiences') &&
                          <>
                            <ArticleTypes
                                type={content_type}
                                serviceType={props.serviceType}
                                textColor={textColor()}
                                backgroundColor={backgroundColor()}
                                content={props.content}
                                app_data={app_data}
                                builder={builder}
                                viewType={viewType}
                                articlesLimit={props.articlesLimit}
                                articlesCategories={Checkers.objChildExists(props, 'articlesCategories') ? props.articlesCategories : null}
                                categoryIdInhrerited={props.categoryIdInhrerited}
                                itemClassName={props.itemClassName}
                                itemWrapperClassName={props.itemWrapperClassName}
                                viewMoreBtnStyle={viewMoreBtnStyle}
                                showAllLabel={showAllLabel}
                                showFullContent={showFullContent}
                            />
                          </>
                    }

                    {
                        (content_type == 'related_properties') &&
                        <>
                          <ArticleTypes
                              type={content_type}
                              serviceType={props.serviceType}
                              textColor={textColor()}
                              backgroundColor={backgroundColor()}
                              content={props.content}
                              app_data={app_data}
                              builder={builder}
                              viewType={viewType}
                              articlesLimit={props.articlesLimit}
                              articlesCategories={null}
                              articles={Checkers.objChildExists(props, 'articlesCategories') ? props.articlesCategories : null}
                              categoryIdInhrerited={props.categoryIdInhrerited}
                              itemWrapperClassName={props.itemWrapperClassName}
                              viewMoreBtnStyle={viewMoreBtnStyle}
                              showFullContent={showFullContent}
                          />
                        </>
                    }

                    {
                        tabsArrayComponents.length>0 && content_type == 'excursion' &&
                        <>
                          {
                            !inViewport ?
                                <>
                                  <div className="pb-5" ref={tabsRef}>
                                    <div className="container">
                                      <div className="row justify-content-center w-100 m-0">
                                        <div className={textColor() + " col-12 col-sm-12 col-md-10 animate-child-height"}>
                                          <div className={'row d-flex'}>
                                            <Skeleton height={30} width={'40%'} className="col-4 col-sm-5 col-md-3 b-rad-md mb-1"/>
                                            <Skeleton height={150} className="col-12 b-rad-lg"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                :
                                <>
                                  <div className="pb-5" style={{background: backgroundColor()}}>
                                    <div className="container">
                                      <div className="row justify-content-center w-100 m-0">
                                        <div className={textColor() + " col-12 col-sm-12 col-md-10 animate-child-height"}>
                                          <TabsContentWrapper data={tabsArrayComponents} ReactComponentsArrayLoading={reactComponentsArrayLoading} tabsMode={'fillUp'} tabsContentClass={'m-t-20 m-b-40'} responsiveDropdown={false} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                          }
                        </>
                    }
                  </>
                </section>
              </>
        }
      </>
  )
}
